import { Box, Button, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormGetValues } from 'react-hook-form'
import InfoIcon from '@mui/icons-material/Info';
import { ButtonLoading } from '../../../../../../../theme/buttons';
import { ProjectStage } from '../../../../../../../interfaces/Projects/Catalogs/projectStages';

type ConfirmChangeProjectStageConsecutiveDialogProps = {
    isEdit: boolean;
    loadingPut: boolean;
    setDialogConfirmChangeProjectStageConsecutive: Dispatch<SetStateAction<boolean>>;
    onPut: (data: ProjectStage) => void;
    restorePreviousValue: () => void;
    getValues: UseFormGetValues<ProjectStage>;
}

export const ConfirmChangeProjectStageConsecutiveDialog = ({
    isEdit,
    loadingPut,
    setDialogConfirmChangeProjectStageConsecutive,
    onPut,
    restorePreviousValue,
    getValues,
}: ConfirmChangeProjectStageConsecutiveDialogProps) => {

    const [t] = useTranslation("global");
    return (
        <Box display="flex" flexDirection="column" justifyContent="start" width="100%">
            <Box width="100%" marginBottom={2}>
                <Typography marginBottom={1}>
                    <InfoIcon />  {t("projectstages.info.change-stages-tasks")}
                </Typography>
                <Typography variant="body2" marginTop={1}>
                    {t("projectstages.info.confirm-change-stages-tasks")}
                </Typography>
            </Box>
            <Box display="flex" flexDirection="row-reverse" width="100%" gap={1}>
                <ButtonLoading
                    disabled={isEdit}
                    isLoading={loadingPut}
                    onClick={() => {
                        const data = getValues();
                        onPut(data);
                        setDialogConfirmChangeProjectStageConsecutive(false);
                    }}
                    className="d-flex align-self-end"
                    variant="outlined"
                >
                    {t("generic.buttons.update")}
                </ButtonLoading>
                <Button
                    variant="outlined"
                    onClick={() => {
                        restorePreviousValue(); // Restaura el valor inicial si se cancela
                        setDialogConfirmChangeProjectStageConsecutive(false);
                    }}
                    disabled={loadingPut}
                >
                    {t("generic.buttons.goback")}
                </Button>
            </Box>
        </Box>
    );
}
