import { useTranslation } from "react-i18next";
import GenericPromises from "../../../../../api/GenericPromises";
import useSnackBar from "../../../../../components/Commons/SnackBar/useSnackBar";
import { FormsErrors } from "../../../../../hooks/Forms/FormsErrors";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import { ProjectStageTemplate } from "../../../../../interfaces/Projects/Catalogs/Templates/projectStagesTemplate";
import { GenericDialog } from "../../../../../components/Dialogs/Dialog";
import { Spinner } from "../../../../../components/Commons/Spinner/Spinner";
import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { Stage } from "../../../../../interfaces/Projects/Catalogs/stages";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { myStylePickers, PrimaryButton } from "../../../../../theme/buttons";
import { DialogEntity } from "../../../../../components/Dialogs/DialogEntity";
import { Header } from "../../../../../components/Header";
import { ConfirmDialog } from "./confirmDialog";
import { Menuitem } from "../../../../../interfaces/Security/menu";
import { FieldType } from "../../../../../interfaces/Projects/Catalogs/fieldTypes";

type UpdateProjectStagesTemplateProps = {
  project_template_id: number | undefined;
  permissions: Menuitem | undefined;
  setOpenDialogUpdateStage: Dispatch<SetStateAction<boolean>>;
  handleUpdateStage: (updateRow: ProjectStageTemplate) => void;
  handleDeleteStage: (deleteRow: number) => void;
  projectStageTemplatePayload: ProjectStageTemplate;
  comboFieldTypes: FieldType[]
};

export const UpdateProjectStagesTemplate = ({
  project_template_id,
  permissions,
  setOpenDialogUpdateStage,
  handleUpdateStage,
  handleDeleteStage,
  projectStageTemplatePayload,
  comboFieldTypes
}: UpdateProjectStagesTemplateProps) => {
  const [t] = useTranslation("global");
  const {
    GenericPutResource,
    GenericDeleteResource,
    GenericGetResource,
    GenericPostResource,
  } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { GetError } = FormsErrors();
  const [loadingPost, setLoadingPost] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [comboStages, setComboStages] = useState<Stage[]>([]);
  const [defaultStage, setDefaultStage] = useState<Stage>();
  const [openStartDateDatePicker, setOpenStartDateDatePicker] = useState(false);
  const [openEndDateDatePicker, setOpenEndDateDatePicker] = useState(false);
  const [dialogConfirmDeleteStage, setDialogConfirmDeleteStage] =
    useState(false);
  const [inputStage, setInputStage] = useState("");
  const isEdit = !permissions?.update;
  const [isTypeFormulaStartedDate, setIsTypeFormulaStartedDate] = useState(false);
  const [isTypeFormulaDueDate, setIsTypeFormulaDueDate] = useState(false);
  const [defaultProjectStageTemplateStartedDate, setDefaultProjectStageTemplateStartedDate] = useState<string | Date>()
  const [defaultStartedDateFieldTypeId, setDefaultStartedDateFieldTypeId] = useState<FieldType>()
  const [defaultProjectStageTemplateDueDate, setDefaultProjectStageTemplateDueDate] = useState<string | Date>()
  const [defaultDueDateFieldTypeId, setDefaultDueDateFieldTypeId] = useState<FieldType>();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm<ProjectStageTemplate>();

    const startedDateFieldTypeId = useWatch({
      control,
      name: "project_stage_template_started_date_field_type_id"
    });
  
    const projectStageTemplateStartedDate = useWatch({
      control,
      name: "project_stage_template_started_date"
    });
  
    const dueDateFieldTypeId = useWatch({
      control,
      name: "project_stage_template_due_date_field_type_id"
    });

  const onSubmit: SubmitHandler<ProjectStageTemplate> = (data) => onPut(data);

  const onPut = (data: ProjectStageTemplate) => {
    let myData = {
      project_template_id: project_template_id,
      stage_id: data.stage_id,
      project_stage_template_name: data.project_stage_template_name,
      project_stage_template_consecutive:
        data.project_stage_template_consecutive,
      project_stage_template_description:
        data.project_stage_template_description || null,
      project_stage_template_started_date:
        data.project_stage_template_started_date || null,
      project_stage_template_due_date:
        data.project_stage_template_due_date || null,
        project_stage_template_started_date_field_type_id:
        data.project_stage_template_started_date_field_type_id || null,
        project_stage_template_due_date_field_type_id:
        data.project_stage_template_due_date_field_type_id || null
    };
    setLoadingPost(true);
    GenericPutResource(
      `/projectstagestemplate/${projectStageTemplatePayload.project_stage_template_id}`,
      myData
    )
      .then((response) => {
        let myUpdateData: ProjectStageTemplate = response.data;
        handleUpdateStage(myUpdateData);
        showSnackBar(t("generic.snackbar.update"), "success");
        setLoadingPost(false);
        setOpenDialogUpdateStage(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setLoadingPost(false);
      });
  };

  const onDelete = () => {
    GenericDeleteResource(
      `/projectstagestemplate/${projectStageTemplatePayload.project_stage_template_id}`
    )
      .then(() => {
        setOpenDialog(false);
        if (projectStageTemplatePayload.project_stage_template_id) {
          handleDeleteStage(
            projectStageTemplatePayload.project_stage_template_id
          );
        }
        setOpenDialogUpdateStage(false);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleAddStage = (stage_name: string) => {
    const existingStage = comboStages.find(
      (stage) => stage.stage_name === stage_name
    );
    if (existingStage) return existingStage;

    let myData = {
      stage_name: stage_name,
      stage_description: null,
    };
    setLoadingPost(true);
    GenericPostResource("/stages", myData)
      .then((response) => {
        setComboStages([...comboStages, response.data]);

        return response.data;
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  };

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  useEffect(() => {
    let myPromises = [GenericGetResource("/stages")];
    Promise.all(myPromises)
      .then((responses) => {
        setComboStages(responses[0].data.data);

        reset({
          project_template_id: projectStageTemplatePayload.project_template_id,
          stage_id: projectStageTemplatePayload.stage_id,
          project_stage_template_name:
            projectStageTemplatePayload.project_stage_template_name,
          project_stage_template_consecutive:
            projectStageTemplatePayload.project_stage_template_consecutive,
          project_stage_template_description:
            projectStageTemplatePayload.project_stage_template_description,
          project_stage_template_started_date:
            projectStageTemplatePayload.project_stage_template_started_date,
          project_stage_template_due_date:
            projectStageTemplatePayload.project_stage_template_due_date || undefined,
            project_stage_template_started_date_field_type_id:
            projectStageTemplatePayload.project_stage_template_started_date_field_type_id,
            project_stage_template_due_date_field_type_id:
            projectStageTemplatePayload.project_stage_template_due_date_field_type_id
        });

        setDefaultStage(
          responses[0].data.data.find(
            (stage: Stage) =>
              stage.stage_id === projectStageTemplatePayload.stage_id
          )
        );

        setDefaultProjectStageTemplateStartedDate(
          projectStageTemplatePayload.project_stage_template_started_date
        );

        const startedDateFieldTypeId=comboFieldTypes.find(
          (fielType: FieldType) =>
            fielType.field_type_id === projectStageTemplatePayload.project_stage_template_started_date_field_type_id
        )

        setDefaultStartedDateFieldTypeId(startedDateFieldTypeId);

        if (startedDateFieldTypeId?.field_type_name.toLowerCase() == 'formula') {
          setIsTypeFormulaStartedDate(true);
        } 

        setDefaultProjectStageTemplateDueDate(
          projectStageTemplatePayload.project_stage_template_due_date
        );

        const dueDateFieldTypeId=comboFieldTypes.find(
          (fielType: FieldType) =>
            fielType.field_type_id === projectStageTemplatePayload.project_stage_template_due_date_field_type_id
        )

        setDefaultDueDateFieldTypeId(dueDateFieldTypeId);

        if (dueDateFieldTypeId?.field_type_name.toLowerCase() == 'formula') {
          setIsTypeFormulaDueDate(true);
        } 

        setDataLoaded(true);
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataLoaded && (
        <div className=" h-25">
          <Spinner isBox={false} />
        </div>
      )}
      {dataLoaded && (
        <Box className="d-flex justify-content-center" sx={{ minWidth: 1000 }}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-100">
            <div className="d-flex mt-1">
              <div className="w-50">
                <Controller
                  name="stage_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboStages}
                      defaultValue={defaultStage}
                      getOptionLabel={(option) => `${option.stage_name}`}
                      renderOption={(props, option: Stage) => (
                        <div key={option.stage_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.stage_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.stage_id === value.stage_id
                      }
                      onInputChange={(_, newInputValue) => {
                        setInputStage(newInputValue);
                      }}
                      onChange={(_, values) => {
                        field.onChange(values?.stage_id || null);
                      }}
                      noOptionsText={
                        <Button
                          onMouseDown={() => {
                            if (inputStage.trim()) {
                              const newStage = handleAddStage(inputStage);
                              field.onChange(newStage?.stage_id || null);
                            }
                          }}
                        >
                          {t("projectstagestemplate.snackbar.actions")}
                        </Button>
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t("projectstagestemplate.fields.stage")}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.stage_id}
                          helperText={GetError(errors.stage_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-50">
                <Controller
                  name="project_stage_template_name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestemplate.fields.project_stage_template_name"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_template_name}
                      helperText={GetError(
                        errors.project_stage_template_name?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_template_consecutive"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestemplate.fields.project_stage_template_consecutive"
                      )}`}
                      ref={field.ref}
                      sx={{
                        paddingRight: 2,
                      }}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_template_consecutive}
                      helperText={GetError(
                        errors.project_stage_template_consecutive?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>

              <div className="w-50">
                <Controller
                  name="project_stage_template_description"
                  control={control}
                  rules={{}}
                  render={({ field }) => (
                    <TextField
                      variant="filled"
                      label={`${t(
                        "projectstagestemplate.fields.project_stage_template_description"
                      )}`}
                      ref={field.ref}
                      value={field.value}
                      onChange={(event) => {
                        field.onChange(event.target.value);
                      }}
                      error={!!errors.project_stage_template_description}
                      helperText={GetError(
                        errors.project_stage_template_description?.type
                      )}
                      size="small"
                      style={{ width: "100%" }}
                      disabled={isEdit}
                    />
                  )}
                />
              </div>
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_template_started_date_field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      defaultValue={defaultStartedDateFieldTypeId}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormulaStartedDate(true);
                        } else {
                          setIsTypeFormulaStartedDate(false);
                        }
                        setValue("project_stage_template_started_date", "", {
                          shouldValidate: true,
                        });
                        setValue("project_stage_template_due_date", "", {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstagestemplate.fields.started_date_field_type_id"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_stage_template_started_date_field_type_id}
                          helperText={GetError(errors.project_stage_template_started_date_field_type_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormulaStartedDate ? (
                <div className="w-50">
                  <Controller
                    name="project_stage_template_started_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projectstagestemplate.fields.project_stage_template_started_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        defaultValue={defaultProjectStageTemplateStartedDate}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_stage_template_started_date}
                        helperText={GetError(
                          errors.project_stage_template_started_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!startedDateFieldTypeId || isEdit ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_stage_template_started_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openStartDateDatePicker}
                        onClose={() => setOpenStartDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projectstagestemplate.fields.project_stage_template_started_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenStartDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_stage_template_started_date,
                            helperText: GetError(
                              errors.project_stage_template_started_date?.type
                            ),
                          },
                        }}
                        format="DD/MM/YYYY"
                        disabled={!startedDateFieldTypeId || isEdit ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="d-flex mt-1 pt-3">
              <div className="w-50">
                <Controller
                  name="project_stage_template_due_date_field_type_id"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      ref={field.ref}
                      size="small"
                      sx={{ width: "100%", paddingRight: 2 }}
                      options={comboFieldTypes}
                      defaultValue={defaultDueDateFieldTypeId}
                      getOptionLabel={(option) => `${option.field_type_name}`}
                      renderOption={(props, option: FieldType) => (
                        <div key={option.field_type_id}>
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.field_type_name}
                          </Box>
                        </div>
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.field_type_id === value.field_type_id
                      }
                      onChange={async (_, values) => {
                        field.onChange(values?.field_type_id || null);
                        if (
                          comboFieldTypes.find(
                            (item) =>
                              item.field_type_id === values?.field_type_id &&
                              item.field_type_name.toLowerCase() === "formula"
                          )
                        ) {
                          setIsTypeFormulaDueDate(true);
                        } else {
                          setIsTypeFormulaDueDate(false);
                        }
                        setValue("project_stage_template_due_date", "", {
                          shouldValidate: true,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t(
                            "projectstagestemplate.fields.due_date_field_type_id"
                          )}`}
                          variant="filled"
                          value={field.value}
                          error={!!errors.project_stage_template_due_date_field_type_id}
                          helperText={GetError(errors.project_stage_template_due_date_field_type_id?.type)}
                          disabled={isEdit}
                        />
                      )}
                    />
                  )}
                />
              </div>
              {isTypeFormulaDueDate ? (
                <div className="w-50">
                  <Controller
                    name="project_stage_template_due_date"
                    control={control}
                    rules={{ maxLength: 50 }}
                    render={({ field }) => (
                      <TextField
                        variant="filled"
                        label={`${t(
                          "projectstagestemplate.fields.project_stage_template_due_date"
                        )}`}
                        ref={field.ref}
                        value={field.value || ""}
                        onChange={(event) => {
                          field.onChange(event.target.value);
                        }}
                        error={!!errors.project_stage_template_due_date}
                        helperText={GetError(
                          errors.project_stage_template_due_date?.type
                        )}
                        size="small"
                        style={{ width: "100%" }}
                        disabled={!projectStageTemplateStartedDate || !dueDateFieldTypeId || isEdit ? true : false}
                      />
                    )}
                  />
                </div>
              ) : (
                <div className="w-50">
                  <Controller
                    name="project_stage_template_due_date"
                    control={control}
                    rules={{}}
                    render={({ field }) => (
                      <DatePicker
                        open={openEndDateDatePicker}
                        onClose={() => setOpenEndDateDatePicker(false)}
                        ref={field.ref}
                        label={`${t(
                          "projectstagestemplate.fields.project_stage_template_due_date"
                        )}`}
                        onChange={(values: any) => {
                          field.onChange(values?.toDate() || null);
                        }}
                        value={field.value ? dayjs(field.value) : null}
                        slotProps={{
                          popper: {
                            sx: myStylePickers
                          },
                          textField: {
                            variant: "filled",
                            size: "small",
                            fullWidth: true,
                            onClick: () => setOpenEndDateDatePicker(true),
                            onBeforeInput: disableKeyboardEntry,
                            error:
                              !!errors.project_stage_template_due_date,
                            helperText: GetError(
                              errors.project_stage_template_due_date?.type
                            ),
                          },
                        }}
                        minDate={dayjs(projectStageTemplateStartedDate)}
                        format="DD/MM/YYYY"
                        disabled={!projectStageTemplateStartedDate || !dueDateFieldTypeId || isEdit ? true : false}
                      />
                    )}
                  />
                </div>
              )}
            </div>

            <div className="d-flex flex-row-reverse mt-4 justify-content-between">
              <div>
                <Button
                  variant="outlined"
                  className="m-1"
                  onClick={() => setOpenDialogUpdateStage(false)}
                >
                  {t("generic.buttons.goback")}
                </Button>
                {permissions?.update && (
                  <PrimaryButton
                    type="submit"
                    variant="outlined"
                    className="m-1"
                    disabled={loadingPost}
                  >
                    {t("generic.buttons.update")}
                  </PrimaryButton>
                )}
              </div>
              {permissions?.delete ? (
                <PrimaryButton
                  onClick={() => setDialogConfirmDeleteStage(true)}
                >
                  {t("generic.buttons.delete")}
                </PrimaryButton>
              ) : (
                <></>
              )}
            </div>
          </form>
        </Box>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projectstagestemplate.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />
      <DialogEntity
        open={dialogConfirmDeleteStage}
        title={<Header title={t("projectstagestemplate.title-view")} />}
        maxWidth={"sm"}
        fullWidth
        content={
          <ConfirmDialog
            getValues={getValues}
            isEdit={isEdit}
            loadingPost={loadingPost}
            setDialogConfirmDeleteStage={setDialogConfirmDeleteStage}
            onDelete={onDelete}
          />
        }
      />
      <SnackbarComponent />
    </>
  );
};
