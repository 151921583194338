import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../../../../../../components/Commons/Spinner/Spinner";
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { GenericDialog } from "../../../../../../components/Dialogs/Dialog";
import { DialogEntity } from "../../../../../../components/Dialogs/DialogEntity";
import GenericPromises from "../../../../../../api/GenericPromises";
import useSnackBar from "../../../../../../components/Commons/SnackBar/useSnackBar";
import { Menuitem } from "../../../../../../interfaces/Security/menu";
import { usePermissions } from "../../../../../../hooks/usePermissions";
import { AuthContext } from "../../../../../../context/AuthContext";
import { defaultTheme } from "../../../../../../theme/theme";
import { ProjectStage } from "../../../../../../interfaces/Projects/Catalogs/projectStages";
import { AddProjectStages } from "./add";
import { Header } from "../../../../../../components/Header";
import { UpdateProjectStages } from "./update";
import { StagesContainer } from "../../../../../../theme/projects/Containers/stagesContainer";
import { StageButton } from "../../../../../../theme/projects/Buttons/stageButton";
import { ProjectExtraField } from "../../../../../../interfaces/Projects/Catalogs/projectExtraFields";
import { Parameter } from "../../../../../../interfaces/Commons/parameters";
import { CheckCircle, CircleOutlined } from "@mui/icons-material";
import { StageItem } from "../components/stageItem";
import { FieldType } from "../../../../../../interfaces/Projects/Catalogs/fieldTypes";

interface TableProjectStagesProps {
  projectId: number | undefined,
  projectExtraFieldsData: ProjectExtraField[],
  parameters: Parameter[];
  comboFieldTypes: FieldType[];
}

export const TableProjectStages = ({
  projectId,
  projectExtraFieldsData,
  parameters,
  comboFieldTypes
}: TableProjectStagesProps) => {
  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [dataloaded, setDataLoaded] = useState(false);
  const { GenericGetResource, GenericDeleteResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const [openDialogAddStage, setOpenDialogAddStage] = useState(false);
  const [openDialogUpdateStage, setOpenDialogUpdateStage] = useState(false);
  const [resourceScreen, setResourceScreen] = useState<Menuitem>();
  const [selectedStage, setSelectedStage] = useState<ProjectStage>();
  const [projectStagesLocal, setProjectStagesLocal] = useState<ProjectStage[]>(
    []
  );
  const { GetResourceByUrl } = usePermissions();
  const { user } = useContext(AuthContext);
  const [color, setColor] = useState<number[]>();
  const [refreshTasks, setRefreshTasks] = useState(false);

  const hexToRgb = (hexColor: string) => {
    hexColor = hexColor.replace("#", "");

    const red = parseInt(hexColor.substring(0, 2), 16);
    const green = parseInt(hexColor.substring(2, 4), 16);
    const blue = parseInt(hexColor.substring(4, 6), 16);

    return [red, green, blue];
  };

  const onDelete = async () => {
    GenericDeleteResource(`/projects/${projectId}`)
      .then(() => {
        setOpenDialog(false);
        navigate("/projects", {
          state: { method: "delete" },
          replace: true,
        });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
        setOpenDialog(false);
      });
  };

  const handleAddStage = (newRow: ProjectStage) => {
    let myRow = {
      ...newRow,
      creation_date: new Date(),
      last_update_user: user?.user_email,
      last_update_date: new Date(),
    };

    setProjectStagesLocal((prevState) => {
      return sortByConsecutive([...projectStagesLocal, myRow]);
    });
  };

  const handleUpdateStage = (updateRow: ProjectStage) => {
    if (projectStagesLocal.length === 0) {
      return;
    }
    setProjectStagesLocal((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.project_stage_id === updateRow.project_stage_id ? updateRow : row
      );
      return sortByConsecutive(updatedRows);
    });
  };

  const handleDeleteStage = (deleteRow: number) => {
    if (projectStagesLocal.length === 0) {
      return;
    }

    setProjectStagesLocal((prevRows) => {
      const rowToDeleteIndex = projectStagesLocal.findIndex(
        (e) => e.project_stage_id === deleteRow
      );

      const updatedData = [
        ...projectStagesLocal.slice(0, rowToDeleteIndex),
        ...projectStagesLocal.slice(rowToDeleteIndex + 1),
      ];

      return sortByConsecutive(updatedData);
    });
  };

  const compareOrderHint = (a: string, b: string): number => {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  };

  const sortByConsecutive = (data: ProjectStage[]) => {
    return data.sort((a: any, b: any) =>
      compareOrderHint(a.project_stage_consecutive, b.project_stage_consecutive)
    );
  };

  useEffect(() => {
    GenericGetResource(`/projectstages/byprojectid/${projectId}`)
      .then(async (response) => {
        setProjectStagesLocal(sortByConsecutive(response.data.data));
        setColor(hexToRgb(defaultTheme.palette.primary.main));
        GetResourceByUrl(`/projectstages`)
          .then((response1) => {
            setResourceScreen((prev) => response1);
            setDataLoaded(true);
          })
          .catch((error) => {
            showSnackBar(error.message, "error");
          });
      })
      .catch((error) => {
        showSnackBar(error.message, "error");
      });
  }, []);

  return (
    <>
      {!dataloaded && <Spinner />}
      {dataloaded && resourceScreen?.read && (
        <>
          <div className="d-flex justify-content-center mt-4">
            <StagesContainer>
              {projectStagesLocal
                .sort(
                  (a, b) =>
                    Number(a.project_stage_consecutive) -
                    Number(b.project_stage_consecutive)
                )
                .map((stage) => (
                  <StageItem key={stage.project_stage_id}
                    isEdit={!resourceScreen?.update}
                    stage={stage}
                    projectExtraFieldsData={projectExtraFieldsData}
                    parameters={parameters}
                    color={color}
                    setSelectedStage={setSelectedStage}
                    setOpenDialogUpdateStage={setOpenDialogUpdateStage}
                    setRefreshTasks={setRefreshTasks}
                    comboFieldTypes={comboFieldTypes}
                  />
                ))}
              {resourceScreen?.create == true && (
                <Grid item xs={8} md={5} style={{ flexShrink: 0 }}>
                  <StageButton
                    color={color}
                    onClick={() => setOpenDialogAddStage(true)}
                  >
                    {t("projectstages.actions.create")}
                  </StageButton>
                </Grid>

              )}
            </StagesContainer>
          </div>
        </>
      )}
      <GenericDialog
        title={t("generic.dialog.delete")}
        content={t("projecttypes.dialog.delete")}
        open={openDialog}
        setOpen={setOpenDialog}
        onAcept={onDelete}
      />

      <DialogEntity
        content={
          <AddProjectStages
            handleAddStage={handleAddStage}
            handleUpdateStage={handleUpdateStage}
            setOpenDialog={setOpenDialogAddStage}
            project_id={projectId}
            comboFieldTypes={comboFieldTypes}
            projectStagesLocal={projectStagesLocal}
          />
        }
        open={openDialogAddStage}
        setOpen={setOpenDialogAddStage}
        title={<Header title={t("projectstages.title-view")} size="sm" />}
      />
      {selectedStage && resourceScreen?.read && (
        <DialogEntity
          content={
            <UpdateProjectStages
              permissions={resourceScreen}
              setOpenDialog={setOpenDialogUpdateStage}
              handleUpdateStage={handleUpdateStage}
              handleDeleteStage={handleDeleteStage}
              projectStagePayload={selectedStage}
              comboFieldTypes={comboFieldTypes}
            />
          }
          open={openDialogUpdateStage}
          setOpen={setOpenDialogUpdateStage}
          title={<Header title={t("projectstages.title-view")} size="sm" />}
        />
      )
      }
      <SnackbarComponent />
    </>
  );
};
