import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import GenericPromises from "../../../api/GenericPromises";
import RandomCubesBackgroundWrapper from "../../../theme/projects/DashBoard/RandomCubesBackgroundWrapper";
import { ProjectType } from "../../../interfaces/Projects/Catalogs/projectTypes";
import useSnackBar from "../../../components/Commons/SnackBar/useSnackBar";
import { Project } from "../../../interfaces/Projects/projects";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import { useColors } from '../../../theme/hooks/useColors';
import { defaultTheme } from "../../../theme/theme";
import { CardStage } from "../../../theme/projects/DashBoard/CardStage";
import { useNavigate } from "react-router-dom";
import { HeaderDashBoard } from "./components/HeaderDashBoard";
import { ProjectStageTemplate } from "../../../interfaces/Projects/Catalogs/Templates/projectStagesTemplate";
import Zoom from '@mui/material/Zoom';
import { ProjectDashboard } from "../../../interfaces/Projects/dashboard";

type GroupedProjects = {
  [stage_id: number]: Project[];
  no_match?: Project[];
};

export const DashBoard = () => {
  const [t] = useTranslation("global");
  const { GenericGetResourceGeneric, GenericGetResource } = GenericPromises();
  const { showSnackBar, SnackbarComponent } = useSnackBar();
  const { HexToRGB } = useColors();
  const navigate = useNavigate();
  const [comboProjectTypes, setComboProjectTypes] = useState<ProjectType[]>([]);
  const [defaultProjectType, setDefaultProjectType] = useState<ProjectType>();
  const [comboProjectStages, setComboProjectStages] = useState<ProjectStageTemplate[]>();
  const [projectsData, setProjectsData] = useState<ProjectDashboard[]>();
  const [filterProjectsData, setFilterProjectsData] = useState<ProjectDashboard[]>();
  const [groupedProjects, setGroupedProjects] = useState<GroupedProjects>({});
  const [color, setColor] = useState<number[]>();
  const [hasProjectsData, setHasProjectsData] = useState(false);
  const [filterValue, setFilterValue] = useState<string>('');

  const handleFilter = (searchTerm: string) => {
    setFilterValue(searchTerm);
    if (projectsData) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filteredData = projectsData.filter((project) =>
        Object.keys(project).some((key) => {
          const fieldValue = project[key as keyof ProjectDashboard];
          return (
            typeof fieldValue === 'string' &&
            fieldValue.toLowerCase().includes(lowerCaseSearchTerm)
          );
        })
      );
      setFilterProjectsData(filteredData);
    }
  };

  const clearFilter = () => {
    setFilterValue('');
    setFilterProjectsData(projectsData);
  };

  // First load on mount component
  useEffect(() => {
    GenericGetResourceGeneric("/companies", "/gcompanies").then((response) => {
      document.title = `${response.data.data[0].comercial_name} - ${t("home.title")}`;
    });

    GenericGetResource(`/projecttypes`)
      .then((responseProjectTypes) => {
        let myComboProjectTypes: ProjectType[] = responseProjectTypes.data.data;
        setComboProjectTypes(myComboProjectTypes);
      })
      .catch((error) => {
        showSnackBar(error.message, 'error');
      });
    setColor(HexToRGB(defaultTheme.palette.primary.main));
  }, []);

  // Find a default project type to show
  useEffect(() => {
    if (!defaultProjectType && comboProjectTypes.length > 0) {
      const foundDefault = comboProjectTypes.find((item) => item.default_show_dashboard === true);
      setDefaultProjectType(foundDefault);
    }
  }, [comboProjectTypes]);

  // load data projects and stages if defaultProjectType has value
  useEffect(() => {
    if (defaultProjectType) {
      // Stages
      setGroupedProjects({});
      setComboProjectStages(undefined);
      setHasProjectsData(false);
      GenericGetResource(`/projectstagestemplate/byprojecttypeid/${defaultProjectType.project_type_id}`)
        .then((responseStages) => {
          if (responseStages.data.data.length > 0) {
            setComboProjectStages(responseStages.data.data);
            setHasProjectsData(true);
          }
          else {
            setComboProjectStages([]);
          }
        })
        .catch((error) => { showSnackBar(error.message, 'error'); });
      // Projects data
      setProjectsData(undefined);
      setHasProjectsData(false);
      GenericGetResource(`/dashboard/currentstage/byprojecttypeid/${defaultProjectType?.project_type_id}`)
        .then((responseProjectsData) => {
          if (responseProjectsData.data.data.length > 0) {
            setProjectsData(responseProjectsData.data.data);
            setHasProjectsData(true);
          }
        })
        .catch((error) => { showSnackBar(error.message, 'error'); });
    }
  }, [defaultProjectType]);

  // Group of projects data in stages first load
  useEffect(() => {
    if (comboProjectStages && projectsData && hasProjectsData) {
      const grouped = projectsData.reduce((acc: GroupedProjects, project: any) => {
        const isMatch = project.stage_id !== undefined && comboProjectStages.some(stage => stage.stage_id === project.stage_id);

        if (isMatch && project.stage_id !== undefined) {
          if (!acc[project.stage_id]) {
            acc[project.stage_id] = [];
          }
          acc[project.stage_id].push(project);
        } else {
          if (!acc["no_match"]) {
            acc["no_match"] = [];
          }
          acc["no_match"].push(project);
        }

        return acc;
      }, {} as GroupedProjects);

      setGroupedProjects(grouped);
    }
  }, [comboProjectStages, projectsData, hasProjectsData]);

  // Group of projects data in stages onChangeFilter
  useEffect(() => {
    if (comboProjectStages && filterProjectsData && hasProjectsData) {
      const grouped = filterProjectsData.reduce((acc: GroupedProjects, project: any) => {
        const isMatch = project.stage_id !== undefined && comboProjectStages.some(stage => stage.stage_id === project.stage_id);
        if (isMatch && project.stage_id !== undefined) {
          if (!acc[project.stage_id]) acc[project.stage_id] = [];
          acc[project.stage_id].push(project);
        } else {
          if (!acc["no_match"]) acc["no_match"] = [];
          acc["no_match"].push(project);
        }
        return acc;
      }, {} as GroupedProjects);
      setGroupedProjects(grouped);
    }
  }, [comboProjectStages, filterProjectsData, hasProjectsData]);

  return (
    <>
      <RandomCubesBackgroundWrapper>
        <HeaderDashBoard
          title={t("dashboard.title")}
          comboProjectTypes={comboProjectTypes}
          defaultProjectType={defaultProjectType}
          setDefaultProjectType={setDefaultProjectType}
          onFilterChange={handleFilter}
          filterValue={filterValue}
          onClearFilter={clearFilter}
        />

        {!hasProjectsData &&
          <Box
            sx={{
              borderRadius: 1,
              backdropFilter: 'blur(5px)',
              boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
              marginBottom: 2,
              height: "6vh",
              backgroundColor: "white",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Typography variant="body2" fontWeight={"Bold"}>
              {t("generic.no-data")}
            </Typography>
          </Box>
        }

        <Box
          sx={{
            height: '100vh',
            overflowX: 'auto',
            display: 'flex',
          }}
        >
          {comboProjectStages && comboProjectStages.length > 0 && comboProjectStages.map((stageLocal, index) => (
            stageLocal.stage_id !== undefined ? (
              <Box
                key={index}
                sx={{
                  minWidth: 300,
                  margin: 1,
                  display: "flex",
                  flexDirection: 'column',
                  maxHeight: '100vh',
                  overflowY: 'auto',
                  scrollbarWidth: 1
                }}
              >
                <Box
                  sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    minHeight: 85,
                  }}
                >
                  <CardStage onClick={() => { }} color={color}>
                    <Typography fontWeight={"bold"} variant="body1">
                      {stageLocal.project_stage_template_name} {`(${groupedProjects[stageLocal.stage_id]?.length ?? 0})`}
                    </Typography>
                    <Tooltip title={stageLocal.project_stage_template_description} TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                      <Typography
                        variant="body2" color={"gray"}
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: 2,
                          maxWidth: '100%',
                          flexGrow: 1,
                        }}
                      >
                        {stageLocal.project_stage_template_description}
                      </Typography>
                    </Tooltip>
                  </CardStage>
                </Box>

                {groupedProjects[stageLocal.stage_id]?.map((project) => (
                  <Box
                    key={project.project_id}
                    sx={{
                      padding: 1,
                      marginBlock: 1,
                      border: '2px solid #ddd',
                      borderRadius: 1,
                      backgroundColor: 'whitesmoke',
                      cursor: 'pointer',
                      boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
                      maxWidth: 300,
                    }}
                    onClick={() => { navigate("/projectsdetails/view", { state: { row: { project_id: project.project_id } } }); }}
                  >
                    <Box display="flex" flexDirection="column" sx={{ width: '100%', maxWidth: '100%' }}>
                      <Typography variant="body1" fontWeight="bold">
                        {project.project_name}
                      </Typography>
                      {project.project_comments && project.project_comments.length > 50 ? (
                        <Tooltip title={project.project_comments} TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                          <Typography
                            variant="body2"
                            color="gray"
                            sx={{
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              WebkitLineClamp: 2,
                              maxWidth: '100%',
                              flexGrow: 1,
                            }}
                          >
                            {project.project_comments}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          variant="body2"
                          color="gray"
                          sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 2,
                            maxWidth: '100%',
                            flexGrow: 1,
                          }}
                        >
                          {project.project_comments}
                        </Typography>
                      )}
                      <Divider sx={{ borderColor: (theme) => theme.palette.primary.main }} />
                      <Typography variant="body2">
                        {project.customer_identifier} {project.business_name}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : null
          ))}

          {groupedProjects["no_match"] && (
            <Box
              sx={{
                minWidth: 300,
                margin: 1,
                display: "flex",
                flexDirection: 'column',
                maxHeight: '100vh',
                overflowY: 'auto',
                scrollbarWidth: 1
              }}
            >
              <Box
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  minHeight: 85,
                }}
              >
                <CardStage onClick={() => { }} color={color}>
                  <Typography fontWeight={"bold"} variant="body1">
                    {t("dashboard.no-match-stage")} ({groupedProjects["no_match"]?.length ?? 0})
                  </Typography>
                </CardStage>
              </Box>

              {groupedProjects["no_match"]?.map((project) => (
                <Box
                  key={project.project_id}
                  sx={{
                    padding: 1,
                    marginBlock: 1,
                    border: '2px solid #ddd',
                    borderRadius: 1,
                    backgroundColor: 'whitesmoke',
                    cursor: 'pointer',
                    boxShadow: '0 2px 3px rgba(0, 0, 0, 0.1)',
                    maxWidth: 300,
                    minHeight: 100,
                  }}
                  onClick={() => { navigate("/projectsdetails/view", { state: { row: { project_id: project.project_id } } }); }}
                >
                  <Box display="flex" flexDirection="column" sx={{ width: '100%', maxWidth: '100%' }}>
                    <Typography variant="body1" fontWeight="bold">
                      {project.project_name}
                    </Typography>
                    {project.project_comments && project.project_comments.length > 50 ? (
                      <Tooltip title={project.project_comments} TransitionComponent={Zoom} TransitionProps={{ timeout: 200 }}>
                        <Typography
                          variant="body2"
                          color="gray"
                          sx={{
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: 2,
                            maxWidth: '100%',
                            flexGrow: 1,
                          }}
                        >
                          {project.project_comments}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        variant="body2"
                        color="gray"
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: 2,
                          maxWidth: '100%',
                          flexGrow: 1,
                        }}
                      >
                        {project.project_comments}
                      </Typography>
                    )}
                    <Divider sx={{ borderColor: (theme) => theme.palette.primary.main }} />
                    <Typography variant="body2">
                      {project.customer_identifier} {project.business_name}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </RandomCubesBackgroundWrapper>
      <SnackbarComponent />
    </>
  )
}
